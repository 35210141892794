import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {LayoutSplashScreen} from "../../_metronic/layout";
const jwt = () => {return JSON.parse(localStorage.getItem('MyToken'));}             

class Logout extends Component {
    componentDidMount() {
        //localStorage.removeItem('MyToken');
        this.logout();
    }

    logout(){
        const removeJwt = () => {return  localStorage.removeItem('MyToken');} 
        removeJwt();
        window.location.reload(); 
    }


    render() {
        return jwt ? <LayoutSplashScreen /> :  <Redirect to={{ pathname: '/login' }} />;
    }
}

export default Logout;
