import React, { Component } from 'react';
import RoadCreate from '../components/RoadCreate';

class CustomerList extends Component {

    constructor(props) {
        super(props);
        this.state = { zonewiseCustomer: [] }
    }
    componentDidMount() {
        this.getZonewiseCustomer();
    }

    getZonewiseCustomer() {
        fetch(process.env.REACT_APP_API_URL + "reports/zonewise_customer", {
            method: "GET",
        })
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {

                this.setState({ zonewiseCustomer: resp.data })
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }


    render() {
        return (
            <div className="card card-custom">

                <div className="card-header">
                    <h3 className="card-title">Zone wise Customer</h3>
                </div>

                <div className="card-body">
                    <div className="col-12">
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>

                                <tr>
                                    <th>Zone Name</th>
                                    <th>Total Customer</th>
                                    <th>Active</th>
                                    <th>Deactive</th>
                                    <th>Closed</th>
                                </tr>

                            </thead>
                            <tbody>
                                {this.state.zonewiseCustomer.map((item, index) =>
                                    <tr>
                                        <td>{item.zoneName}</td>
                                        <td>{item.totalCustomer}</td>
                                        <td>{item.activeCustomer}</td>
                                        <td>{item.deactiveCustomer}</td>
                                        <td>{item.closedCustomer}</td>

                                    </tr>
                                )}



                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default CustomerList;