import React, { Component } from 'react';
import { Spinner } from "react-bootstrap";
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getGetRequestOptions } from "../../components/GetToken";
import ToastMsg from '../../components/ToastMsg';

const customStylesSelect = {
    control: (provided) => ({
        ...provided,
        height: 'calc(1.35em + 1.1rem + 2px)',
        minHeight: '35px'
    }),
    valueContainer: (provided) => ({
        ...provided,
    })
};

class CustomerCollectionReport extends Component {

    state = {
        businessUnit: process.env.REACT_APP_EMD_BU_ID,
        zone: "",
        fromDate: "",
        toDate: "",
        reportData: [],
        zoneOption: [],
        showReport: false,
        errors: {
            fromDate: "",
            toDate: "",
        },
        touched: {
            fromDate: false,
            toDate: false
        }

    }

    componentDidMount() {
        const { zoneOption, ...baseState } = this.state;
        this.baseState = baseState;
        this.getAllZone();
    }

    handleInputOnChange = evt => {
        this.setState({ [evt.target.name]: evt.target.value });
    };


    handleZoneChange = zone => {
        this.setState({ zone: zone });
    }


    getAllZone() {
        fetch(process.env.REACT_APP_API_URL + "zone", {
            method: "GET",
        })
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {

                const allOption = [{
                    "id": "all",
                    "name": "All",
                    "label": "All",
                    "value": "all"
                }];

                const tempResp = resp.data.map(function (element) {
                    return { ...element, label: element.name, value: element.id }
                });

                const newTempResp = allOption.concat(tempResp);
               
                this.setState({ zoneOption: newTempResp });

                //this.setState({ zoneOption: resp.data });
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }

    getAllBu() {
        fetch(process.env.REACT_APP_API_URL + "get_business_units",       
        getGetRequestOptions())
        .then((resp) => {
            return resp.json()
        })
        .then((resp) => {
            const tempResp = resp.data.map(function (element) {
                return { ...element, label: element.projectName, value: element.projectId, isdisabled: element.projectName == 'EMD' ? false : true }
            }).filter(row => row.projectId != process.env.REACT_APP_EMD_BU_ID);
            const newTempResp = tempResp.concat([{
                "projectId": "all",
                "projectName": "All",
                "companyId": "",
                "label": "All",
                "value": "all"
            }]);
            this.setState({ zoneOption: newTempResp });
        })
        .catch((error) => {
            console.log(error, "catch the hoop")
        });
    }

    excelExport = (zoneId, fromDate, toDate) => {
        var link = process.env.REACT_APP_API_URL.split("api/")[0] + "reports/customer_collection_excel_export/" + zoneId;
        window.open(link, '_blank');
    }

    getReportData() {
        this.setState({ loading: true });

        const { zone, fromDate, toDate } = this.state;

        const formData = {
            zone: zone,
            fromDate: fromDate,
            toDate: toDate
        };

        //console.log(formData);
        const jwt = () => { return JSON.parse(localStorage.getItem('MyToken')); }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt().token },
            body: JSON.stringify(formData)
        };
        fetch(process.env.REACT_APP_API_URL + "reports/customer_collection", requestOptions)
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                this.setState({ loading: false });

                if (resp.success == true) {
                    this.setState({
                        reportData: resp.data
                    });
                }
                else {
                    //var errorsMessage = "";
                    var errorsMessage = [];

                    if (resp.errorMessage != undefined && typeof resp.errorMessage === 'object') {
                        var errorsObj = resp.errorMessage;
                        Object.keys(errorsObj).forEach(function (value) {
                            errorsObj[value].forEach(function (v) {
                                errorsMessage.push(v)
                                //errorsMessage += '<div>' + v + '</div>';
                            });

                        });

                    } else if (resp.errorMessage != undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                        //errorsMessage = resp.errorMessage;
                        errorsMessage.push(resp.errorMessage);
                    } else {
                        //errorsMessage = "Something went wrong";
                        errorsMessage.push("Something went wrong");
                    }
                    //console.log(errorsMessage);
                    toast.error(<ToastMsg toastMessage={errorsMessage} heading={resp.heading} />, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }


            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log(error, "catch the hoop")
            });

    }



    fetchData = (inputValue, callback) => {
        if (!inputValue) {
            //callback([]);
            var api = process.env.REACT_APP_API_URL + "get_employee";
        } else {
            api = process.env.REACT_APP_API_URL + "get_employee?q=" + inputValue;
        }

        setTimeout(() => {
            fetch(api,getGetRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                const tempArray = resp.data.map(function (element) {
                    return { ...element, id: element.employeeId, label: `${element.employeeName} ${element.employeeCode}`, value: element.employeeId }
                });

                callback(tempArray);
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
        });
    }


    handleSubmit = evt => {
        evt.preventDefault();
        this.setState({ showReport: true });
        this.getReportData();
    }

    render() {

        const { errors, touched } = this.state;
        const excelExportEndPoint = process.env.REACT_APP_API_URL.split("api/")[0]+'report/emo_details_excel_export';
        const token = JSON.parse(localStorage.getItem('MyToken'));

        return (

            <>
                <div className="card card-custom">
                    <div className="card-header">
                        <div className="card-title">
                            <h3 className="card-label"><p className="text-primary">Customer Collection Report</p></h3>
                        </div>

                    </div>

                    <form onSubmit={this.handleSubmit}>
                        <div className="card-body">
                            <div className="ml-12">

                                <div className="form-group row">
                                    <label htmlFor="fromDate" className="col-lg-2 col-form-label" style={{ "textAlign": "center" }}>From  Date</label>
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <input type="date" name="fromDate" id="fromDate" value={this.state.fromDate} onChange={this.handleInputOnChange} className={`form-control form-control-md ${touched.fromDate == true ? (errors.fromDate == "" ? 'is-valid' : 'is-invalid') : ''}`} />
                                            </div>
                                            <div className="col-lg-7">
                                                <div className="form-group row">
                                                    <label htmlFor="toDate" className="col-lg-3 col-form-label" style={{ "textAlign": "center" }}>To Date</label>
                                                    <div className="col-lg-9">
                                                        <input type="date" name="toDate" id="toDate" value={this.state.toDate} onChange={this.handleInputOnChange} className={`form-control form-control-md ${touched.toDate == true ? (errors.toDate == "" ? 'is-valid' : 'is-invalid') : ''}`} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="example-text-input" className="col-lg-2 col-form-label" style={{ "textAlign": "center" }}>Zone</label>
                                    <div className="col-lg-6">
                                        <Select
                                            value={this.state.zone}
                                            onChange={this.handleZoneChange}
                                            options={this.state.zoneOption}
                                            styles={customStylesSelect}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">

                                    <div className="col-lg-2">
                                    </div>
                                    <div className="col-lg-6">
                                        <button type="submit" className="btn btn-success mr-2">Ok</button>

                                    </div>

                                </div>
                                {/* <pre>
                            {JSON.stringify(this.state, null, 2)}
                        </pre> */}

                            </div>

                        </div>

                    </form>

                    {this.state.showReport != "" ?
                        <div className="card-body">
                            {this.state.loading && <div className="text-center"> <Spinner animation="grow" /><Spinner animation="grow" /><Spinner animation="grow" /></div>}
                            {!this.state.loading && <>  <div className="card-toolbar mt-5 mr-10  mb-5 ">
                        {/* <button className="btn btn-primary btn-sm " onClick={() => this.excelExport(this.state.zone.value,this.state.fromDate,this.state.toDate)}>Excel Export</button> */}
                        { this.state.reportData.length > 0 && <a href={`${excelExportEndPoint}/${this.state.zone.value}/${this.state.fromDate}/${this.state.toDate}?token=${token.token}`}  className="btn btn-primary btn-sm mr-3"><i className="flaticon2-download-1"></i>Excel Export</a> }
                    </div>
                    <div className="table-responsive">
                                <table className="table table-bordered ">
                                    <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>Date</th>
                                            <th>Zone</th>
                                            <th>Collection By</th>
                                            <th>Account Head Name</th>
                                            <th>Customer Name</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.reportData.map((item, index) =>
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.voucherDate}</td>
                                                <td>{item.zoneName}</td>
                                                <td>{item.collectBy}</td>
                                                <td>{item.accHead}</td>
                                                <td>{item.customerName}</td>
                                                <td>{item.amount}</td>
                                            </tr>
                                        )
                                        }
                                    </tbody>

                                </table>
                            </div></>}
                        </div> : ""}
                </div>
                <ToastContainer />
            </>

        );
    }
}

export default CustomerCollectionReport;