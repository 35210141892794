import React, { useState } from 'react'
import CustomerDetail from '../components/CustomerDetails'
import '../styles/customerSearch.css'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import { set } from 'lodash'
import { is, tr } from 'date-fns/locale'

const CustomerSearch = () => {
  const [query, setQuery] = useState('')
  const [customers, setCustomers] = useState([])
  const [error, setError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSearch = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}customer_search?q=${query}`,
        getRequestOptions()
      )
      const data = await response.json()
      setCustomers(data.data)
      setError('')
      setIsSubmitting(true)
    } catch (err) {
      setError('Customer not found')
      setCustomers([])
    }
  }

  const handleInputChange = e => {
    setQuery(e.target.value)
  }

  return (
    <div className='card card-custom'>
      <div className='card-header'>
        <h3 className='card-title'>Customer Search</h3>
      </div>

      <div className='card-body'>
        <div style={{ marginLeft: '-12.5px', marginRight: '-12.5px' }}>
          <div className='customer-search-container'>
            <div className='search-bar'>
              <input
                type='text'
                placeholder='Search by ID or name'
                value={query}
                onChange={handleInputChange}
              />
              <button onClick={handleSearch}>
                <span role='img' aria-label='search'>
                  🔍
                </span>{' '}
                Search
              </button>
            </div>

            {error && <div className='error'>{error}</div>}
            {customers.length > 0 && <CustomerDetail customers={customers} />}

            {isSubmitting && customers.length === 0  && (
              <div className='no-results'>
                <p>No customers found.</p>
              </div>
            )}

          </div>
        </div>
      </div>
    </div>
  )
}
export default CustomerSearch
