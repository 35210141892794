import React, { useState, useEffect } from "react";
import { Tab, Tabs, Nav, Col, Row, Button, InputGroup, FormControl, Modal } from "react-bootstrap";
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import AsyncSelect from 'react-select/async';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastMsg from '../components/ToastMsg';
import Swal from 'sweetalert2';

const customStylesSelect = {
    control: (provided) => ({
        ...provided,
        height: 'calc(1.5em + 1.3rem + 2px)'
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: 'calc(1.5em + 1.3rem + 2px)'
    })
};


const SmsTemplate = (props) => {

    const [smsTemplate, setSmsTemplate] = useState(null);
    const [smsTemplateMessage, setSmsTemplateMessage] = useState(null);
    const [smsTemplateOption, setSmsTemplateOption] = useState([]);

    useEffect(() => {
        getAllSmsTemplate();
    },[]);

    useEffect(() => {
        getSmsTemplateMessage();
    },[smsTemplate]);
   

    const handleSmsTemplateChange = (evt) =>{

        setSmsTemplate(evt.target.value);

    }
    const handleSmsTemplateMessageChange = (evt) =>{

        setSmsTemplateMessage(evt.target.value);    

    }

    const getAllSmsTemplate = () => {
       const smsTemplateOption = [
           {'key':'bill_create','value':'Bill Create'},
           {'key':'bill_receive','value':'Bill Receive'},
       ]

        setSmsTemplateOption(smsTemplateOption);

    }


    const  getSmsTemplateMessage = () => {
        
        const jwt = () => { return JSON.parse(localStorage.getItem('MyToken')); }

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt()
            }
        };
        fetch(process.env.REACT_APP_API_URL + "get_sms_template/"+smsTemplate , requestOptions)
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                setSmsTemplateMessage(resp.value);
            })
            .catch((error) => {
                console.log(error)
            })

        
    }

    const handleSubmit = evt => {

        evt.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to update SMS Template!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                const formData = {
                    smsTemplateMessage:smsTemplateMessage,
                    smsTemplate:smsTemplate
                }
                console.log(formData);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(formData)
                };
                fetch(process.env.REACT_APP_API_URL + "update_sms_template", requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        console.log(resp);

                        if (resp.success == true) {
                            let successMsg = ["SMS Template updated successfully"];

                            toast.success(<ToastMsg toastMessage={successMsg} heading={resp.successMessage} />, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            
                            //this.props.history.push('/user-list');
                        }
                        else {
                            //var errorsMessage = "";
                            var errorsMessage = [];

                            if (resp.errorMessage != undefined && typeof resp.errorMessage === 'object') {
                                var errorsObj = resp.errorMessage;
                                Object.keys(errorsObj).forEach(function (value) {
                                    errorsObj[value].forEach(function (v) {
                                        errorsMessage.push(v)
                                        //errorsMessage += '<div>' + v + '</div>';
                                    });

                                });

                            } else if (resp.errorMessage != undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                                //errorsMessage = resp.errorMessage;
                                errorsMessage.push(resp.errorMessage);
                            } else {
                                //errorsMessage = "Something went wrong";
                                errorsMessage.push("Something went wrong");
                            }
                            //console.log(errorsMessage);
                            toast.error(<ToastMsg toastMessage={errorsMessage} heading={resp.heading} />, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }


                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            }

        })

    }

    return (

        <div className="card card-custom">

            <div className="card-header">
                <h3 className="card-title">SMS Template</h3>
            </div>
            <div className="col-md-12">
                <div className="featured-box featured-box-primary text-left mt-2">
                    <div className="box-content">

                        <form onSubmit={handleSubmit} >

                            <div className="form-group row">
                                <label for="customer" className="col-lg-2 col-form-label">SMS Template</label>
                                <div className="col-lg-10">
                                <select className="form-control" id="smsTemplate" name="smsTemplate" value={smsTemplate} onChange={handleSmsTemplateChange} >
                                    <option value="">Select Template</option>
                                    {smsTemplateOption.map((option) => (
                                        <option key={option.key} value={option.key}>{option.value}</option>
                                    ))}
                                </select>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label for="smsTemplateMessage" className="col-lg-2 col-form-label">Template Message</label>
                                <div className="col-lg-10">
                                   <textarea className="form-control" rows={5} value={smsTemplateMessage} onChange={handleSmsTemplateMessageChange} ></textarea> 

                                </div>
                            </div>

                            {/* <pre>
                                {JSON.stringify(this.state, null, 2)}
                            </pre> */}

                            <div className="form-group row">

                                <div className="form-group col-lg-5">
                                    <input type="submit" value="Save" className="btn btn-primary btn-modern float-left" data-loading-text="Loading..." />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default SmsTemplate;