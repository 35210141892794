import React, { Component } from 'react';
import AreaDT from '../components/Areadt';
import AreaCreate from '../components/AreaCreate';

class Area extends Component {

    constructor(props) {
        super(props);
        this.state = { newArea: "", id: "", name: "" }
    }
    onCreate = (newArea) => {
        console.log(newArea);
        this.setState({ newArea: newArea });
    }
    onAction = (id, name) => {
        this.setState({ id: id, name: name });
    }
    render() {
        return (
            <div className="card card-custom">

                <div className="card-header">
                    <h3 className="card-title"> Area Manage</h3>
                </div>
                
                <div className="card-body">
                    <AreaCreate onCreate={this.onCreate} id={this.state.id} name={this.state.name} />
                    <AreaDT addnewArea={this.state.newArea} onAction={this.onAction} />
                </div>

            </div >

        );
    }
}

export default Area;