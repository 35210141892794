import React, { Component } from 'react';
import Userdt from '../components/Userdt';

class UserList extends Component {

    constructor(props) {
        super(props);
        this.state = { newRoad:""}
    }
    onCreate = (newRoad) =>  {
        console.log(newRoad);
        this.setState({newRoad:newRoad});
    }
    render() {
        return (
            <div className="card card-custom">

                <div className="card-header">
                    <h3 className="card-title">User  List</h3>
                </div>
                
                <div className="card-body">
                    <Userdt/>
                </div>

            </div>

        );
    }
}

export default UserList;