import React, { Component } from 'react';
import { Tab, Tabs, Nav, Col, Row, Button, InputGroup, FormControl, Modal } from "react-bootstrap";


class PaymentDetails extends Component {
    state = {
        paymentId: "",
        customer: { name: "", id: "", code: "", address: "" },
        paymentId: "",
        paymentNo: "",
        paymentMonth: "",
        paymentDate: "",
        paymentYear: "",
        total: "",
        netPayable: "",
        dueAmount: "",
        items: []
    }

    componentDidMount() {
        const {
            params: { id }
        } = this.props.match;
        //this.setState({paymentId: id}); 
        console.log(this.props.match.params.id);
        this.setState({ paymentId: this.props.match.params.id });
        this.getPaymentDetails(id);
    }


    getPaymentDetails(paymentId) {
        fetch(process.env.REACT_APP_API_URL + "payment_details/" + paymentId, {
            method: "GET",
        })
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                this.setState({
                    customerName: resp.customerName,
                    amount: resp.amount,
                    paymentDate: resp.paymentDate,
                    remarks : resp.remarks,
                    collectionBy: resp.collectionBy,
                    ledgerName : resp.ledgerName,
                    customerCode: resp.customerCode
                });

            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }
    render() {
        return (
            <div className="card card-custom">

                <div className="card-header">
                    <h3 className="card-title"> Payment  Details</h3>
                </div>

             
                    <div className="featured-box featured-box-primary text-left mt-2">
                        <div className="box-content">
                          

                            <div className="col-12">
                                <table className="table table-hover">

                                    <tbody>

                                        <tr>
                                            <th style={{ 'width': '30%' }}>Payment Date</th>
                                            <td style={{ 'width': '70%' }}>{this.state.paymentDate}</td>
                                        </tr>

                                        <tr>
                                            <th>Customer</th>
                                            <td>{this.state.customerName}</td>
                                        </tr>

                                        <tr>
                                            <th>Customer Code</th>
                                            <td>{this.state.customerCode}</td>
                                        </tr>


                                        <tr>
                                            <th>Amount</th>
                                            <td>{this.state.amount}</td>
                                        </tr>

                                        <tr>
                                            <th>Remarks</th>
                                            <td>{this.state.remarks}</td>
                                        </tr>

                                        <tr>
                                            <th>Collection By</th>
                                            <td>{this.state.collectionBy}</td>
                                        </tr>

                                        <tr>
                                            <th>Collection  Account</th>
                                            <td>{this.state.ledgerName}</td>
                                        </tr>

                                    </tbody>
                                </table>

                                {/* <pre>
                            {JSON.stringify(this.state.details, null, 2)}
                        </pre> */}
                            </div>


                            
                        </div>
                    </div>
                
            </div>

        );
    }
}

export default PaymentDetails;