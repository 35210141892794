import React, { Component } from 'react';
import CustomerDT from '../components/Customerdt';
import RoadCreate from '../components/RoadCreate';

class CustomerList extends Component {

    constructor(props) {
        super(props);
        this.state = { newRoad:""}
    }
    onCreate = (newRoad) =>  {
        console.log(newRoad);
        this.setState({newRoad:newRoad});
    }
    render() {
        return (
            <div className="card card-custom">

                <div className="card-header">
                    <h3 className="card-title">Customer  List</h3>
                </div>
                
                <div className="card-body">
                    <div style={{marginLeft:"-12.5px",marginRight:"-12.5px"}}>
                    <CustomerDT/>
                    </div>
                </div>

            </div>

        );
    }
}

export default CustomerList;