import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {BuilderPage} from "./pages/BuilderPage";
import Logout from "./pages/Logout";
import {DashboardPage} from "./pages/DashboardPage";
import CustomerReg from "./pages/CustomerReg";
import CustomerNetwork from "./pages/CustomerNetwork";
import CustomerUpdate from "./pages/CustomerUpdate";
import CustomerList from "./pages/CustomerList";
import CustomerDetails from "./pages/CustomerDetails";
import UserCreate from "./pages/UserCreate";
import UserList from "./pages/UserList";
import UserUpdate from "./pages/UserUpdate";
import Billing from "./pages/Billing";
import BillList from "./pages/BillList";
import BillDetails from "./pages/BillDetails";
import BillCreate from "./pages/BillCreate";
import BillUpdate from "./pages/BillUpdate";
import PaymentList from "./pages/PaymentList";
import PaymentDetails from "./pages/PaymentDetails";
import PaymentCollection from "./pages/PaymentCollection";
import Area from "./pages/Area";
import Zone from "./pages/Zone";
import Package from "./pages/Package";
import CustomerCollectionReport from "./pages/reports/CustomerCollectionReport";
import SendSmsCustomer from "./pages/SendSmsCustomer";
import SendSmsZonewise from "./pages/SendSmsZonewise";
import SmsTemplate from "./pages/SmsTemplate";
import CustomerSearch from "./pages/CustomerSearch";

// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <ContentRoute path="/dashboard" component={DashboardPage}/>
                <ContentRoute path="/builder" component={BuilderPage}/>
                <ContentRoute path="/customer-reg" component={CustomerReg}/>
                <ContentRoute path="/customer-network" component={CustomerNetwork}/>
                <ContentRoute path="/customer-list" component={CustomerList} />
                <ContentRoute path="/customer-details/:id" component={CustomerDetails} />
                <ContentRoute path="/customer-edit/:id" component={CustomerUpdate} />
                <ContentRoute path="/customer-search" component={CustomerSearch} />
                <ContentRoute path="/user-create" component={UserCreate}/>
                <ContentRoute path="/user-list" component={UserList} />
                <ContentRoute path="/user-edit/:id" component={UserUpdate} />
                <ContentRoute path="/bill-generates" component={Billing} />
                <ContentRoute path="/bill-list" component={BillList} />
                <ContentRoute path="/bill-details/:id" component={BillDetails} />
                <ContentRoute path="/bill-create" component={BillCreate} />
                <ContentRoute path="/bill-edit/:id" component={BillUpdate} />
                <ContentRoute path="/payment-list" component={PaymentList} />
                <ContentRoute path="/payment-details/:id" component={PaymentDetails} />
                <ContentRoute path="/payment-collection" component={PaymentCollection} />
                <ContentRoute path="/report-customer-collection" component={CustomerCollectionReport} />
                <ContentRoute path="/sms-send-customer" component={SendSmsCustomer} />
                <ContentRoute path="/sms-send-zonewise" component={SendSmsZonewise} />
                <ContentRoute path="/sms-template" component={SmsTemplate} />
                <Route path="/setup-area" component={Area} />
                <Route path="/setup-zone" component={Zone} />
                <Route path="/setup-package" component={Package} />
                {/* <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/> */}
                <Route path="/logout" component={Logout} />
                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
