import React, { Component } from 'react';
import { Tab, Tabs, Nav, Col, Row, Button, InputGroup, FormControl, Modal } from "react-bootstrap";
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import AsyncSelect from 'react-select/async';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastMsg from '../components/ToastMsg';
import Swal from 'sweetalert2';

const customStylesSelect = {
    control: (provided) => ({
        ...provided,
        height: 'calc(1.5em + 1.3rem + 2px)'
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: 'calc(1.5em + 1.3rem + 2px)'
    })
};


class BillCreate extends Component {
    state = {
        billId: "",
        customer: "",
        billId: "",
        billNo: "",
        billMonth: "",
        billDate: "",
        billYear: "",
        total: "",
        netPayable: "",
        dueAmount: "",
        items: [{
            "item": "",
            "unit": "",
            "quantity": "",
            "stock": "",
            "price": "",
            "amount": ""
        }],
        sendSms: 'yes'
    }

    componentDidMount() {
        const {
            params: { id }
        } = this.props.match;
        //this.setState({billId: id}); 
        console.log(this.props.match.params.id);
        this.setState({ billId: this.props.match.params.id });
        this.getBillDetails(id);
    }


    getBillDetails(billId) {
        fetch(process.env.REACT_APP_API_URL + "bill_details/" + billId, {
            method: "GET",
        })
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                this.setState({
                    billId: resp.id,
                    billNo: resp.billNo,
                    billMonth: resp.billMonth,
                    billYear: resp.billYear,
                    billDate: resp.billDate,
                    total: resp.total,
                    customer: resp.customer,
                    dueAmount: resp.dueAmount,
                    netPayable: resp.netPayable,
                    items: resp.bill_item
                });

                this.calcTotal();

            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }

    getCustomer = (inputValue, callback) => {
        if (!inputValue) {
            //callback([]);
            var url = process.env.REACT_APP_API_URL + "customer_search";

        } else {
            var url = process.env.REACT_APP_API_URL + "customer_search?q=" + inputValue;
        }
        const jwt = () => { return JSON.parse(localStorage.getItem('MyToken')); }
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt() },

        };

        setTimeout(() => {
            fetch(url, requestOptions)
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    const tempArray = resp.data.map(function (element) {
                        element.label = element.name + element.mobileNo;
                        element.value = element.id;
                        return element;
                    });
                    callback(tempArray);
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        });
    }

    handleCustomerChange = (customer) => {

        this.setState({
            customer: customer
        });
    }

    handleInputOnChange = evt => {
        this.setState({ [evt.target.name]: evt.target.value });
    };

    onquantityChange = (index) => evt => {
        const newItems = this.state.items.map(function (row, rindex) {
            if (index === rindex) {
                row.quantity = Number(evt.target.value);
                row.amount = ((Number(row.quantity) * Number(row.price)) - Number(row.discount)).toFixed(2);
            }
            return row;
        })

        this.setState({ items: newItems });
        this.calcTotal();
    }

    onDiscountChange = (index) => evt => {
        const newItems = this.state.items.map(function (row, rindex) {
            if (index === rindex) {
                row.discount = Number(evt.target.value);
                row.amount = ((Number(row.quantity) * Number(row.price)) - Number(row.discount)).toFixed(2);
            }
            return row;
        })

        this.setState({ items: newItems });
        this.calcTotal();
    }

    onItemChange = index => item => {
        var price = 0;
         if(item.itemId =='1') 
            price =  Number(this.state.customer.monthlyFee) ;
         else if(item.itemId =='2') 
          price = Number(this.state.customer.connectionCharge);
        const newItems = this.state.items.map(function (row, rindex) {
            if (index !== rindex) return row;
            return { ...row, item: item, unit: item.unitName,quantity:1,price: price,discount: Number(0),amount:  Number(price)  }
        });
        this.setState({ items: newItems },()=>  this.calcTotal());
      

    }

    onPriceChange = index => evt => {

        const newItems = this.state.items.map(function (row, rindex) {
            if (index === rindex) {
                row.price = evt.target.value;
                row.amount = (Number(row.quantity) * Number(row.price)).toFixed(2);
            }
            return row;
        })

        this.setState({ items: newItems });
        this.calcTotal();
    }

    objPsum = (items, prop) => {
        return items.reduce(function (a, b) {
            return Number(a) + Number(b[prop]);
        }, 0);
    }
    calcTotal = () => {
        this.setState({ totalDiscount: (this.objPsum(this.state.items, 'discount')).toFixed(2) })
        this.setState({ total: (this.objPsum(this.state.items, 'amount')).toFixed(2) })

    }

    addItem = () => {
        this.setState({
            items: this.state.items.concat([{ warehouse: "", item: "", unit: "", quantity: "", stock: "", price: "", amount: "" }])
        });
    }

    removeItem = (index) => {
       
        this.setState({
            items: this.state.items.filter((row, rindex) => index !== rindex)
        }, () => this.calcTotal());

    }


    fetchItemData = (inputValue, callback) => {
        if (!inputValue) {
            //callback([]);
            setTimeout(() => {
                fetch(process.env.REACT_APP_API_URL + "item/item_search", {
                    method: "GET",
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        const tempArray = resp.data.map(function (element) {
                            return { ...element, id: element.itemId, label: element.itemName, value: element.itemId }
                        });
                        callback(tempArray);
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            });
        } else {
            setTimeout(() => {
                fetch(process.env.REACT_APP_API_URL + "item/item_search?q=" + inputValue, {
                    method: "GET",
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        const tempArray = resp.data.map(function (element) {
                            element.id = element.itemId;
                            element.label = element.itemName;
                            element.value = element.itemId;
                            return element;
                        });
                        callback(tempArray);
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            });
        }
    }

    handleSubmit = evt => {

        evt.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to create User!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
        const { areaOption, blockOption, ...formData } = this.state;
        //console.log(formData);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };
        fetch(process.env.REACT_APP_API_URL + "bill_save", requestOptions)
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                console.log(resp);

                if (resp.success == true) {
                    let successMsg = [`Bill ID# ${resp.data.id}`];

                    toast.success(<ToastMsg toastMessage={successMsg} heading={resp.successMessage} />, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    this.setState(this.baseState);
                    this.props.history.push('/bill-list');
                }
                else {
                    //var errorsMessage = "";
                    var errorsMessage = [];

                    if (resp.errorMessage != undefined && typeof resp.errorMessage === 'object') {
                        var errorsObj = resp.errorMessage;
                        Object.keys(errorsObj).forEach(function (value) {
                            errorsObj[value].forEach(function (v) {
                                errorsMessage.push(v)
                                //errorsMessage += '<div>' + v + '</div>';
                            });

                        });

                    } else if (resp.errorMessage != undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                        //errorsMessage = resp.errorMessage;
                        errorsMessage.push(resp.errorMessage);
                    } else {
                        //errorsMessage = "Something went wrong";
                        errorsMessage.push("Something went wrong");
                    }
                    //console.log(errorsMessage);
                    toast.error(<ToastMsg toastMessage={errorsMessage} heading={resp.heading} />, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }


            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
        }

    })


    }

    render() {
        return (
            <div className="card card-custom">

                <div className="card-header">
                    <h3 className="card-title"> Bill  Create</h3>
                </div>
                <div className="col-md-12">
                    <div className="featured-box featured-box-primary text-left mt-2">
                        <div className="box-content">

                            <form onSubmit={this.handleSubmit} >

                                <div className="form-group row">
                                    <label for="customer" className="col-lg-2 col-form-label">Customer</label>
                                    <div className="col-lg-10">
                                        <AsyncSelect
                                            id="customer"
                                            value={this.state.customer}
                                            defaultOptions
                                            loadOptions={this.getCustomer}
                                            //loadOptions={promiseOptions}
                                            placeholder="Select Customer"
                                            onChange={this.handleCustomerChange}
                                        // onChange={(e) => {
                                        //this.onSearchChange(e);
                                        //  }}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                <label for="customer" className="col-lg-2 col-form-label">Bill Date</label>
                                    <div className="col-lg-10">    
                                <input type="date" className="form-control"  name="billDate" value={this.state.billDate} onChange={this.handleInputOnChange} />
                                    </div>


                                </div>

                                    {/* <pre>
                                        {JSON.stringify(this.state, null, 2)}
                                    </pre> */}
                                

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="kt-section">
                                            <div className="kt-separator kt-separator--dashed"></div>
                                            <div className="table-responsive">
                                            <table className="table table-bordered table-condensed">

                                                <tbody>

                                                    <tr>
                                                        <th style={{ 'width': '5%' }}>Sl.</th>
                                                        <th style={{ width: '15%', minWidth: '200px' }}>Item</th>
                                                        <th style={{ 'width': '10%' }}>Unit</th>
                                                        <th style={{ 'width': '10%' }}>Quantity</th>
                                                        <th style={{ 'width': '10%' }}>Price</th>
                                                        {/* <th style={{ 'width': '10%' }}>Discount</th> */}
                                                        <th style={{ 'width': '10%' }}>Amount</th>
                                                        <th style={{ 'width': '10%' }}>Action</th>
                                                    </tr>
                                                    {this.state.items.map((item, index) =>
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td><AsyncSelect
                                                                key={index}
                                                                value={item.item}
                                                                defaultOptions
                                                                loadOptions={this.fetchItemData}
                                                                //loadOptions={promiseOptions}
                                                                placeholder="Select Item"
                                                                onChange={this.onItemChange(index)}
                                                                styles={customStylesSelect}
                                                            // onChange={(e) => {
                                                            //this.onSearchChange(e);
                                                            //  }}
                                                            />
                                                            </td>
                                                            <td>{item.item.unit}</td>
                                                            <td><input className="form-control" type="number" value={item.quantity} onChange={this.onquantityChange(index)} /></td>
                                                            <td><input className="form-control" type="number" value={item.price} onChange={this.onPriceChange(index)} /></td>
                                                            {/* <td><input className="form-control" type="number" value={item.discount} onChange={this.onDiscountChange(index)} /></td> */}
                                                            <td>{item.amount}</td>
                                                            <td><button type="button" className="btn btn-danger btn-sm" onClick={()=>this.removeItem(index)}>Remove</button></td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <td colSpan="8">
                                                            <div className="form-group row">
                                                                <div className="col-lg-2">
                                                                    <button type="button" className="btn btn-primary btn-sm" onClick={this.addItem}>Add Item</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    {/* <tr>
                                                        <th colSpan="5" className="text-right">Discount </th>
                                                        <th>{this.state.totalDiscount}</th>
                                                    </tr> */}
                                                    <tr>
                                                        <th colSpan="5" className="text-right">Subtotal </th>
                                                        <th>{this.state.total}</th>
                                                    </tr>
                                                </tfoot>

                                            </table>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <br />
                                <div className="form-group row">
                                    <label htmlFor="sendSms" className="col-lg-2 ">Send SMS</label>
                                    <div className="col-lg-10">
                                    <label className="checkbox">
                                        <input 
                                            type="checkbox" 
                                            name="sendSms" 
                                            id="sendSms" 
                                            checked={this.state.sendSms === "yes"} 
                                            onChange={(e) => this.setState({ sendSms: e.target.checked ? "yes" : "no" })}
                                        />
                                        <span></span>
                                    </label>
                                    </div>
                                </div>

                                <div className="form-group row">

                                    <div className="form-group col-lg-5">
                                        <input type="submit" value="Save" className="btn btn-primary btn-modern float-left" data-loading-text="Loading..." />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>

        );
    }
}

export default BillCreate;