import React, { Component } from 'react';
import { Tab, Tabs, Nav, Col, Row, Button, InputGroup, Modal } from "react-bootstrap";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ToastMsg from '../components/ToastMsg';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


class CustomerReg extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            mobileNo: "",
            alternateMobileNo: "",
            code: "",
            email: "",
            website: "",
            connectionDate: "",
            connectionCharge: "",
            monthlyFee: "",
            billDate: 1,
            package: "",
            category: "regular",
            status: "active",
            area: "",
            zone: "",
            line: "",
            road: "",
            address: "",
            gpsLocation: "",
            nid: "",
            birthCertificateNo: "",
            passportNo: "",
            drivingLicenceNo: "",
            isPppoeOrStatic: "pppoe",
            pppoe: "",
            pppoeUserName: "",
            pppoePassword: "",
            localAddress: "",
            remoteAddress: "",
            callerId: "",
            ipAddress: "",
            subnetMask: "",
            defaultGateway: "",
            primaryDns: "",
            alternateDns: "",
            onu: "",
            onuMacAddress: "",
            router: "",
            routerLogin: "",
            remoteManagement:"",
            wifi: "",
            note: "",
            areaOption: [],
            zoneOption: [],
            packageOption: []
        };
    }

    componentDidMount() {
        this.baseState = this.state;
        this.getAllArea();
        this.getAllZone();
        this.getAllPackage();
    }


    getAllArea() {
        fetch(process.env.REACT_APP_API_URL + "area", {
            method: "GET",
        })
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                this.setState({ areaOption: resp.data });
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }

    getAllZone() {
        fetch(process.env.REACT_APP_API_URL + "zone", {
            method: "GET",
        })
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                this.setState({ zoneOption: resp.data });
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }

    getAllPackage() {
        fetch(process.env.REACT_APP_API_URL + "package", {
            method: "GET",
        })
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                this.setState({ packageOption: resp.data });
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }

    getAreaBlock(areaId) {
        fetch(process.env.REACT_APP_API_URL + "area_block/" + areaId, {
            method: "GET",
        })
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                this.setState({ blockOption: resp.data });
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }


    getBlockRoad(blockId) {
        fetch(process.env.REACT_APP_API_URL + "block_road/" + blockId, {
            method: "GET",
        })
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                this.setState({ roadOption: resp.data });
                this.getBlockLine(blockId);
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }

    getBlockLine(blockId) {
        fetch(process.env.REACT_APP_API_URL + "block_line/" + blockId, {
            method: "GET",
        })
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                this.setState({ lineOption: resp.data });
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }

    handleInputOnChange = evt => {
        this.setState({ [evt.target.name]: evt.target.value });
    };

    handleAreaChange = evt => {
        if (evt.target.value != "") {
            this.getAreaBlock(evt.target.value);
        } else {
            this.setState({ blockOption: [] });
        }
        this.setState({ area: evt.target.value });
    }

    handleBlockChange = evt => {
        if (evt.target.value != "") {
            this.getBlockRoad(evt.target.value);
        } else {
            this.setState({ roadOption: [] });
        }
        this.setState({ block: evt.target.value });
    }


    handleSubmit = evt => {

        evt.preventDefault();
        const { areaOption, blockOption, ...formData } = this.state;
        console.log(formData);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };
        fetch(process.env.REACT_APP_API_URL + "customer_reg", requestOptions)
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                console.log(resp);

                if (resp.success == true) {
                    let successMsg = [`Customer ID# ${resp.data.id}`];

                    toast.success(<ToastMsg toastMessage={successMsg} heading={resp.successMessage} />, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    this.setState(this.baseState);
                    this.props.history.push('/customer-list');
                }
                else {
                    //var errorsMessage = "";
                    var errorsMessage = [];

                    if (resp.errorMessage != undefined && typeof resp.errorMessage === 'object') {
                        var errorsObj = resp.errorMessage;
                        Object.keys(errorsObj).forEach(function (value) {
                            errorsObj[value].forEach(function (v) {
                                errorsMessage.push(v)
                                //errorsMessage += '<div>' + v + '</div>';
                            });

                        });

                    } else if (resp.errorMessage != undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                        //errorsMessage = resp.errorMessage;
                        errorsMessage.push(resp.errorMessage);
                    } else {
                        //errorsMessage = "Something went wrong";
                        errorsMessage.push("Something went wrong");
                    }
                    //console.log(errorsMessage);
                    toast.error(<ToastMsg toastMessage={errorsMessage} heading={resp.heading} />, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }


            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }

    render() {
        const categoryOption = [{ name: 'Regular', value: 'regular' }, { name: 'Complimentary', value: 'complimentary' }];
        const statusOption = [{ name: 'Active', value: 'active' }, { name: 'Deactive', value: 'deactive' }, { name: 'Close', value: 'close' }];
        return (
            <div className="card card-custom">
                <div className="card-header">
                    <h3 className="card-title">
                        Customer Registration
                </h3>
                </div>


                <div className="card-body">

                    <form onSubmit={this.handleSubmit} >
                    <div className="row">
                    <div className="col-md-5">
                        <div className="form-group row">
                            <TextField name="code" value={this.state.code} onChange={this.handleInputOnChange} label="ID" variant="outlined" size="small" fullWidth />
                        </div>
                        <div className="form-group row">
                            <TextField name="name" value={this.state.name} onChange={this.handleInputOnChange} label="Name" style={{ marginBottom: 10 }} variant="outlined" size="small" fullWidth />
                        </div>

                        <div className="form-group row">

                            <TextField name="mobileNo" value={this.state.mobileNo} onChange={this.handleInputOnChange} label="Mobile Number" variant="outlined" size="small" fullWidth />

                        </div>

                        <div className="form-group row">

                            <TextField name="alternateMobileNo" value={this.state.alternateMobileNo} onChange={this.handleInputOnChange} label="Alternate Mobile Number" variant="outlined" size="small" fullWidth />

                        </div>

                        <div className="form-group row">
                            <TextField name="email" value={this.state.email} onChange={this.handleInputOnChange} label="Email" variant="outlined" size="small" fullWidth />
                        </div>

                        <div className="form-group row">
                            <TextField name="website" value={this.state.website} onChange={this.handleInputOnChange} label="Website" variant="outlined" size="small" fullWidth />
                        </div>

                        <div className="form-group row">
                            <TextField name="nid" value={this.state.nid} onChange={this.handleInputOnChange} label="National Id/Passport Number" variant="outlined" size="small" fullWidth />
                        </div>

                        <div className="form-group row">

                            <div className="col-6">
                                <FormControl variant="outlined" size="small" fullWidth >
                                    <InputLabel htmlFor="">Area</InputLabel>
                                    <Select label="Area" name="area" onChange={this.handleAreaChange}>
                                        <MenuItem value="">Select Area</MenuItem >
                                        {this.state.areaOption.map(function (item, id) {
                                            return <MenuItem key={id} value={item.areaId}>{item.areaName}</MenuItem>
                                        })
                                        }
                                    </Select>
                                </FormControl>

                            </div>
                            <div className="col-6">
                                <FormControl variant="outlined" size="small" fullWidth  >
                                    <InputLabel htmlFor="" >Zone</InputLabel>
                                    <Select label="Zone" name="zone" onChange={this.handleInputOnChange} >
                                        <MenuItem value="">Select Block</MenuItem>
                                        {this.state.zoneOption.map((item, id) =>
                                            <MenuItem key={id} value={item.id}>{item.name}</MenuItem>
                                        )}

                                    </Select>
                                </FormControl>
                            </div>

                        </div>


                        <div className="form-group row">

                            <TextField multiline rows={2} name="address" value={this.state.address} onChange={this.handleInputOnChange} label="Address" variant="outlined" size="small" fullWidth />

                        </div>
                        

                        <div className="form-group row">

                            <TextField name="connectionCharge" value={this.state.connectionCharge} onChange={this.handleInputOnChange} label="Connection Charge" variant="outlined" size="small" fullWidth />

                        </div>

                        <div className="form-group row">

                            <TextField type="date" name="connectionDate" value={this.state.connectionDate} onChange={this.handleInputOnChange} InputLabelProps={{
                                shrink: true,
                            }} label="Connection Date" variant="outlined" size="small" fullWidth />

                        </div>
                        <div className="form-group row">
                            <FormControl variant="outlined" size="small" fullWidth >
                                <InputLabel htmlFor="">Package</InputLabel>
                                <Select label="Package" name="package" onChange={this.handleInputOnChange}>
                                    <MenuItem value="">Select Package</MenuItem >
                                    {this.state.packageOption.map(function (item, id) {
                                        return <MenuItem key={id} value={item.id}>{item.name}</MenuItem>
                                    })
                                    }
                                </Select>
                            </FormControl>
                        </div>

                        <div className="form-group row">

                            <TextField name="monthlyFee" value={this.state.monthlyFee} onChange={this.handleInputOnChange} label="Monthly Fee" variant="outlined" size="small" fullWidth />

                        </div>

                        <div className="form-group row">

                            <TextField type="number" name="billDate" value={this.state.billDate} onChange={this.handleInputOnChange} label="Bill Date" variant="outlined" size="small" fullWidth />

                        </div>

                        <div className="form-group row">

                            <TextField name="gpsLocation" value={this.state.gpsLocation} onChange={this.handleInputOnChange} label="GPS Location" variant="outlined" size="small" fullWidth />

                        </div>

                        <div className="form-group row">

                            <FormControl variant="outlined" size="small" fullWidth  >
                                <InputLabel htmlFor="" >Category</InputLabel>
                                <Select label="Category" value={this.state.category} name="category" onChange={this.handleInputOnChange} >
                                    <MenuItem value="">Select Category</MenuItem>
                                    {categoryOption.map(function (item, id) {
                                        return <MenuItem key={id} value={item.value}>{item.name}</MenuItem>
                                    })
                                    }
                                </Select>
                            </FormControl>

                        </div>

                        <div className="form-group row">

                            <FormControl variant="outlined" size="small" fullWidth  >
                                <InputLabel htmlFor="" >Status</InputLabel>
                                <Select label="Status" name="status" onChange={this.handleInputOnChange} >
                                    <MenuItem value="">Select Status</MenuItem>
                                    {statusOption.map(function (item, id) {
                                        return <MenuItem key={id} value={item.value}>{item.name}</MenuItem>
                                    })
                                    }
                                </Select>
                            </FormControl>

                        </div>




                        </div>
                        <div className="col-md-2">&nbsp;</div>
                        <div className="col-md-5">
                            
                       
                        <div className="form-group row">  
                        <FormControl>
                       
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="isPppoeOrStatic" value={this.state.isPppoeOrStatic} onChange={this.handleInputOnChange}
                        >
                            <FormControlLabel value="pppoe" control={<Radio />} label="PPPoE" />
                            <FormControlLabel value="static" control={<Radio />} label="Static" />
                            
                        </RadioGroup>
                        </FormControl>
                        </div>

                        
                        {this.state.isPppoeOrStatic === "pppoe" && <>
                        <div className="form-group row">

                            <TextField type="text" name="pppoe" value={this.state.pppoe} onChange={this.handleInputOnChange} label="PPPoE" variant="outlined" size="small" fullWidth />

                        </div>
                        <div className="form-group row">

                            <div className="col-lg-6">
                                <TextField name="pppoeUserName" value={this.state.pppoeUserName} onChange={this.handleInputOnChange} label="User Name" variant="outlined" size="small" fullWidth />
                            </div>

                            <div className="col-lg-6">
                                <TextField name="pppoePassword" value={this.state.pppoePassword} onChange={this.handleInputOnChange} label="Password" variant="outlined" size="small" fullWidth />
                            </div>

                        </div>
                        <div className="form-group row">

                            <TextField type="text" name="localAddress" value={this.state.localAddress} onChange={this.handleInputOnChange} label="Local Address" variant="outlined" size="small" fullWidth />

                        </div>

                        <div className="form-group row">

                            <TextField type="text" name="remoteAddress" value={this.state.remoteAddress} onChange={this.handleInputOnChange} label="Remote Address" variant="outlined" size="small" fullWidth />

                        </div>
                        <div className="form-group row">

                            <TextField type="text" name="callerId" value={this.state.callerId} onChange={this.handleInputOnChange} label="Caller ID" variant="outlined" size="small" fullWidth />

                        </div>
                        </>
                       }
                        {this.state.isPppoeOrStatic === "static" && <>
                        <div className="form-group row">

                        <TextField type="text" name="ipAddress" value={this.state.ipAddress} onChange={this.handleInputOnChange} label="IP Address" variant="outlined" size="small" fullWidth />

                        </div>

                        <div className="form-group row">

                        <TextField type="text" name="subnetMask" value={this.state.subnetMask} onChange={this.handleInputOnChange} label="Subnet Mask" variant="outlined" size="small" fullWidth />

                        </div>

                        <div className="form-group row">

                        <TextField type="text" name="defaultGateway" value={this.state.defaultGateway} onChange={this.handleInputOnChange} label="Default Gateway" variant="outlined" size="small" fullWidth />

                        </div>
                        <div className="form-group row">

                        <TextField type="text" name="primaryDns" value={this.state.primaryDns} onChange={this.handleInputOnChange} label="Primary DNS" variant="outlined" size="small" fullWidth />

                        </div>
                        <div className="form-group row">

                        <TextField type="text" name="alternateDns" value={this.state.alternateDns} onChange={this.handleInputOnChange} label="Alternate DNS" variant="outlined" size="small" fullWidth />

                        </div>

                        </>
                       }

                    <div className="form-group row">

                    <TextField type="text" name="onu" value={this.state.onu} onChange={this.handleInputOnChange} label="ONU Name" variant="outlined" size="small" fullWidth />

                    </div>

                    <div className="form-group row">

                    <TextField type="text" name="onuMacAddress" value={this.state.onuMacAddress} onChange={this.handleInputOnChange} label="ONU Mac Address" variant="outlined" size="small" fullWidth />

                    </div>
                    <div className="form-group row">

                    <TextField multiline rows={2} type="text" name="note" value={this.state.note} onChange={this.handleInputOnChange} label="Note" variant="outlined" size="small" fullWidth />

                    </div>

                    <div className="form-group row">

                    <TextField multiline rows={2} type="text" name="router" value={this.state.router} onChange={this.handleInputOnChange} label="Router" variant="outlined" size="small" fullWidth />

                    </div>
                    <div className="form-group row">

                    <TextField multiline rows={2} type="text" name="routerLogin" value={this.state.routerLogin} onChange={this.handleInputOnChange} label="Router Login" variant="outlined" size="small" fullWidth />

                    </div>
                    <div className="form-group row">

                    <TextField multiline rows={2} type="text" name="remoteManagement" value={this.state.remoteManagement} onChange={this.handleInputOnChange} label="Router Remote Management" variant="outlined" size="small" fullWidth />

                    </div>
                    <div className="form-group row">

                    <TextField multiline rows={2} type="text" name="wifi" value={this.state.wifi} onChange={this.handleInputOnChange} label="WiFi" variant="outlined" size="small" fullWidth />

                    </div>




                        </div>
                        <div className="form-group row">

                        <div className="form-group col-lg-5">
                            <input type="submit" value="Save" className="btn btn-primary btn-modern float-left" data-loading-text="Loading..." />
                        </div>
                        </div>
                        </div>
                    </form>
                    

                </div>
                {/* <pre>
                    {JSON.stringify(this.state, null, 2)}
                </pre> */}

                <ToastContainer />
            </div>



        );
    }
}

export default CustomerReg;