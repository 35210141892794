import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { Tab, Tabs, Nav, Col, Row, Button, InputGroup, Modal } from "react-bootstrap";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import ToastMsg from '../components/ToastMsg';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {email:"",password:""};
    }

    handleInputOnChange = evt => {
        this.setState({ [evt.target.name]: evt.target.value });
    };


    handleSubmit = evt => {

        evt.preventDefault();
        const formData = this.state;
        console.log(formData);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };
        fetch(process.env.REACT_APP_API_URL + "auth/login", requestOptions)
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                console.log(resp);

                if (resp.success == true) {

                    localStorage.setItem("MyToken", JSON.stringify(resp.token));
                    window.location.reload();   
                    //this.props.history.push('/home');
                }
                else {
                    //var errorsMessage = "";
                    var errorsMessage = [];

                    if (resp.errorMessage != undefined && typeof resp.errorMessage === 'object') {
                        var errorsObj = resp.errorMessage;
                        Object.keys(errorsObj).forEach(function (value) {
                            errorsObj[value].forEach(function (v) {
                                errorsMessage.push(v)
                                //errorsMessage += '<div>' + v + '</div>';
                            });

                        });

                    } else if (resp.errorMessage != undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                        //errorsMessage = resp.errorMessage;
                        errorsMessage.push(resp.errorMessage);
                    } else {
                        //errorsMessage = "Something went wrong";
                        errorsMessage.push("Something went wrong");
                    }
                    //console.log(errorsMessage);
                    toast.error(<ToastMsg toastMessage={errorsMessage} heading={resp.heading} />, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }


            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }
   
    render() {
        const  jwt = JSON.parse(localStorage.getItem('MyToken'));
        if(jwt) {
            return <Redirect to={{ pathname: '/' }} />
         
        }
          
        return (
            <div className="login login-4 login-signin-on d-flex flex-row-fluid" id="kt_login">
            <div className="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat" style={ {'background-color': '#fff'}}>
                <div className="login-form text-center p-7 position-relative overflow-hidden">

                    <div className="d-flex flex-center mb-15">
                        <a href="#">
                            <img src="assets/media/logos/logo-letter-13.png" className="max-h-75px" alt=""/>
                        </a>
                    </div>
                
                    <div className="login-signin">
                        <div className="mb-20">
                            <h3>Sign In To Billing</h3>
                            <div className="text-muted font-weight-bold">Enter your details to login to your account:</div>
                        </div>
                        <form onSubmit={this.handleSubmit} className="form" id="kt_login_signin_form">
                            <div className="form-group mb-5">
                                <input className="form-control h-auto form-control-solid py-4 px-8" type="text" placeholder="Email" name="email" value={this.state.email} onChange={this.handleInputOnChange}  autocomplete="off" />
                            </div>
                            <div className="form-group mb-5">
                                <input className="form-control h-auto form-control-solid py-4 px-8" type="password" placeholder="Password" name="password" value={this.state.password} onChange={this.handleInputOnChange}  />
                            </div>
                           
                            <button id="kt_login_signin_submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4">Sign In</button>
                        </form>
                        
                    </div>
            
                
                </div>
            </div>
            <ToastContainer />
        </div>
   
        );
    }
}

export default Login;