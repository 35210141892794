import React, { Component } from 'react';
import { Tab, Tabs, Nav, Col, Row, Button, InputGroup, FormControl, Modal } from "react-bootstrap";


class BillDetails extends Component {
    state = {
        billId: "",
        customer: { name: "", id: "", code: "", address: "" },
        billId: "",
        billNo: "",
        billMonth: "",
        billDate: "",
        billYear: "",
        total: "",
        netPayable: "",
        dueAmount: "",
        items: []
    }

    componentDidMount() {
        const {
            params: { id }
        } = this.props.match;
        //this.setState({billId: id}); 
        console.log(this.props.match.params.id);
        this.setState({ billId: this.props.match.params.id });
        this.getBillDetails(id);
    }


    getBillDetails(billId) {
        fetch(process.env.REACT_APP_API_URL + "bill_details/" + billId, {
            method: "GET",
        })
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                this.setState({
                    billId: resp.id,
                    billNo: resp.billNo,
                    billMonth: resp.billMonth,
                    billYear: resp.billYear,
                    billDate: resp.billDate,
                    total: resp.total,
                    customer: resp.customer,
                    dueAmount: resp.dueAmount,
                    netPayable: resp.netPayable,
                    items: resp.bill_item
                });

            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }
    render() {
        return (
            <div className="card card-custom">

                <div className="card-header">
                    <h3 className="card-title"> Bill  Details</h3>
                </div>

             
                    <div className="featured-box featured-box-primary text-left mt-2">
                        <div className="box-content">
                          

                            <div className="col-12">
                                <table className="table table-hover">

                                    <tbody>
                                        <tr>
                                            <th style={{ 'width': '30%' }}>Invoice No</th>
                                            <td style={{ 'width': '70%' }}>{this.state.billNo}</td>
                                        </tr>
                                        <tr>
                                            <th>Bill Month</th>
                                            <td>{this.state.billMonth + '-' + this.state.billYear}</td>
                                        </tr>

                                        <tr>
                                            <th>Bill Date</th>
                                            <td>{this.state.billDate}</td>
                                        </tr>

                                        <tr>
                                            <th>Customer</th>
                                            <td>{this.state.customer.name}</td>
                                        </tr>

                                        <tr>
                                            <th>Customer Code</th>
                                            <td>{this.state.customer.code}</td>
                                        </tr>


                                    </tbody>
                                </table>

                                {/* <pre>
                            {JSON.stringify(this.state.details, null, 2)}
                        </pre> */}
                            </div>


                            <div className="row">
                                <div className="col-md-12">
                                    <div className="kt-section">
                                        <div className="kt-separator kt-separator--dashed"></div>

                                        <table className="table table-bordered table-condensed">

                                            <tbody>

                                                <tr>
                                                    <th>Sl.</th>
                                                    <th>Item</th>
                                                    <th>Unit</th>
                                                    <th>Quantity</th>
                                                    <th>Price</th>
                                                    <th>Amount</th>
                                                </tr>
                                                {this.state.items.map((item, index) =>
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{item.item.itemName}</td>
                                                        <td>{item.item.unit}</td>
                                                        <td>{item.quantity}</td>
                                                        <td>{item.price}</td>
                                                        <td>{item.price * item.quantity}</td>
                                                    </tr>
                                                )}

                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan="5" className="text-right"> Total </td>
                                                    <td>{this.state.total}</td>
                                                </tr>
                                            </tfoot>

                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
            </div>

        );
    }
}

export default BillDetails;