import React, { Component } from 'react';
import { Tab, Tabs, Nav, Col, Row, Button, InputGroup, Modal } from "react-bootstrap";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import ToastMsg from '../components/ToastMsg';
import Swal from 'sweetalert2';

class UserCreate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            mobileNo: "",
            password: "",
            confirmPassword: "",
            zone: "",
            usertype: "2",
            zoneOption: [],
        };
    }

    componentDidMount() {
        this.baseState = this.state;
        this.getAllZone();

    }

    getAllZone() {
        fetch(process.env.REACT_APP_API_URL + "zone", {
            method: "GET",
        })
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                this.setState({ zoneOption: resp.data });
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }

    handleInputOnChange = evt => {
        this.setState({ [evt.target.name]: evt.target.value });
    };


    handleCustomerChange = (customer) => {

        this.setState({
            customer: customer
        }, () => {
            this.getCustomerDetails();
        });
    }

    getCustomerDetails() {

    }


    handleSubmit = evt => {

        evt.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to create User!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                const { zoneOption, ...formData } = this.state;
                console.log(formData);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(formData)
                };
                fetch(process.env.REACT_APP_API_URL + "user_create", requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        console.log(resp);

                        if (resp.success == true) {
                            let successMsg = [`User ID# ${resp.data.id}`];

                            toast.success(<ToastMsg toastMessage={successMsg} heading={resp.successMessage} />, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            this.setState(this.baseState);
                            this.props.history.push('/user-list');
                        }
                        else {
                            //var errorsMessage = "";
                            var errorsMessage = [];

                            if (resp.errorMessage != undefined && typeof resp.errorMessage === 'object') {
                                var errorsObj = resp.errorMessage;
                                Object.keys(errorsObj).forEach(function (value) {
                                    errorsObj[value].forEach(function (v) {
                                        errorsMessage.push(v)
                                        //errorsMessage += '<div>' + v + '</div>';
                                    });

                                });

                            } else if (resp.errorMessage != undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                                //errorsMessage = resp.errorMessage;
                                errorsMessage.push(resp.errorMessage);
                            } else {
                                //errorsMessage = "Something went wrong";
                                errorsMessage.push("Something went wrong");
                            }
                            //console.log(errorsMessage);
                            toast.error(<ToastMsg toastMessage={errorsMessage} heading={resp.heading} />, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }


                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            }

        })

    }


    render() {
        const userTypes = [{ name: 'Seper Admin', id: '1' }, { name: 'General User', id: '2' }];

        return (
            <div className="card card-custom">
                <div className="card-header">
                    <h3 className="card-title">
                        User Create
                </h3>
                </div>


                <div className="card-body">

                    <form onSubmit={this.handleSubmit} >


                        <div className="form-group row">
                            <label for="name" className="col-lg-2 col-form-label">Name</label>
                            <div className="col-lg-6">
                                <input className="form-control" type="text" name="name" id="name" value={this.state.name} onChange={this.handleInputOnChange} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="mobileNo" className="col-lg-2 col-form-label">Mobile No</label>
                            <div className="col-lg-6">
                                <input className="form-control" type="text" name="mobileNo" id="mobileNo" value={this.state.mobileNo} onChange={this.handleInputOnChange} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="email" className="col-lg-2 col-form-label">User Name/ Email</label>
                            <div className="col-lg-6">
                                <input className="form-control" type="text" name="email" id="email" value={this.state.email} onChange={this.handleInputOnChange} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="password" className="col-lg-2 col-form-label">Password</label>
                            <div className="col-lg-6">
                                <input className="form-control" type="password" name="password" id="password" value={this.state.password} onChange={this.handleInputOnChange} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label for="confirmPassword" className="col-lg-2 col-form-label">Confirm Password</label>
                            <div className="col-lg-6">
                                <input className="form-control" type="password" name="confirmPassword" id="confirmPassword" value={this.state.confirmPassword} onChange={this.handleInputOnChange} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="password" className="col-lg-2 col-form-label">Zone</label>
                            <div className="col-lg-6">
                                <select className="form-control" id="zone" name="zone" value={this.state.zone} onChange={this.handleInputOnChange} >
                                    <option value="">Select Zone</option>
                                    {this.state.zoneOption.map(item =>
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    )}
                                </select>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="password" className="col-lg-2 col-form-label">User Type</label>
                            <div className="col-lg-6">
                                <select className="form-control" id="usertype" name="usertype" value={this.state.usertype} onChange={this.handleInputOnChange} >
                                    <option value="">Select User Type</option>
                                    {userTypes.map(item =>
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    )}
                                </select>
                            </div>
                        </div>


                        <div className="form-group row">

                            <div className="form-group col-lg-5">
                                <input type="submit" value="Save" className="btn btn-primary btn-modern float-left" data-loading-text="Loading..." />
                            </div>
                        </div>

                    </form>

                </div>
                {/* <pre>
                    {JSON.stringify(this.state, null, 2)}
                </pre> */}

                <ToastContainer />
            </div>



        );
    }
}

export default UserCreate;