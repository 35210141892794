import React, { Component } from 'react';
import ZoneDt from '../components/Zonedt';
import ZoneCreate from '../components/ZoneCreate';

class Zone extends Component {

    constructor(props) {
        super(props);
        this.state = { newZone: "", id: "", name: "" }
    }
    onCreate = (newZone) => {
        console.log(newZone);
        this.setState({ newZone: newZone });
    }
    onAction = (id, name) => {
        this.setState({ id: id, name: name });
    }
    render() {
        return (
            <div className="card card-custom">

                <div className="card-header">
                    <h3 className="card-title"> Zone Manage</h3>
                </div>
                
                <div className="card-body">
                    <ZoneCreate onCreate={this.onCreate} id={this.state.id} name={this.state.name} />
                    <ZoneDt addnewZone={this.state.newZone} onAction={this.onAction} />
                </div>

            </div >

        );
    }
}

export default Zone;