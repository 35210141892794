import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import TextField from '@material-ui/core/TextField';
import { toast, ToastContainer } from 'react-toastify';
import CustomerBillList from '../components/CustomerBillList';

const customStylesSelect = {
  control: (provided) => ({
    ...provided,
    height: 'calc(1.5em + 1.3rem + 2px)'
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: 'calc(1.5em + 1.3rem + 2px)'
  })
};

class PaymentCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: "",
      month: new Date(),
      zone: "",
      zoneOption: []
    }
  }

  componentDidMount() {
    this.baseState = this.state;
    this.getAllZone();

  }

  getAllZone() {
    fetch(process.env.REACT_APP_API_URL + "zone", {
      method: "GET",
    })
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        this.setState({ zoneOption: resp.data });
      })
      .catch((error) => {
        console.log(error, "catch the hoop")
      });

  }

  getCustomer = (inputValue, callback) => {
    if (!inputValue) {
      //callback([]);
      var url = process.env.REACT_APP_API_URL + "customer_search";

    } else {
      var url = process.env.REACT_APP_API_URL + "customer_search?q=" + inputValue;
    }
    const jwt = () => { return JSON.parse(localStorage.getItem('MyToken')); }
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt() },

    };
    
    setTimeout(() => {
      fetch(url, requestOptions)
        .then((resp) => {
          return resp.json()
        })
        .then((resp) => {
          const tempArray = resp.data.map(function (element) {
            element.label = element.name + element.mobileNo;
            element.value = element.id;
            return element;
          });
          callback(tempArray);
        })
        .catch((error) => {
          console.log(error, "catch the hoop")
        });
    });
  }

  handleCustomerChange = (customer) => {

    this.setState({
      customer: customer
    }, () => {
      this.getCustomerBill();
    });
  }

  getCustomerBill() {
    const { areaOption, blockOption, ...formData } = this.state;
    console.log(formData);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData)
    };
    fetch(process.env.REACT_APP_API_URL + "customer_bill_list", requestOptions)
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        console.log(resp);


      })
      .catch((error) => {
        console.log(error, "catch the hoop")
      });

  }

  handleInputOnChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  render() {
    return (

      <div className="card card-custom">
        <div className="card-header">
          <h3 className="card-title">
            Bill Collection
        </h3>
        </div>
        <div className="col-md-12">
          <div className="featured-box featured-box-primary text-left mt-2">
            <div className="box-content">

              {/* <div className="form-group row">
                <label for="password" className="col-lg-2 col-form-label">Zone</label>
                <div className="col-lg-10">
                  <select className="form-control" id="zone" name="zone" value={this.state.zone} onChange={this.handleInputOnChange} >
                    <option value="">Select Zone</option>
                    {this.state.zoneOption.map(item =>
                      <option key={item.id} value={item.id}>{item.name}</option>
                    )}
                  </select>
                </div>
              </div> */}


              <div className="form-group row">
                <label for="customer" className="col-lg-2 col-form-label">Customer</label>
                <div className="col-lg-10">
                  <AsyncSelect
                    id="customer"
                    value={this.state.customer}
                    defaultOptions
                    loadOptions={this.getCustomer}
                    //loadOptions={promiseOptions}
                    placeholder="Select Customer"
                    onChange={this.handleCustomerChange}
                  // onChange={(e) => {
                  //this.onSearchChange(e);
                  //  }}
                  />
                </div>
              </div>
              {/* <pre>
                {JSON.stringify(this.state, null, 2)}
              </pre> */}
            { (this.state.customer != "") ? <CustomerBillList  customer={this.state.customer}/> : ""}

            </div>
          </div>
        </div>


    
        <ToastContainer />
      </div>

    );
  }
}

export default PaymentCollection;