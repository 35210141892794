import React, { useState, useEffect } from "react";
import { Tab, Tabs, Nav, Col, Row, Button, InputGroup, FormControl, Modal } from "react-bootstrap";
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import AsyncSelect from 'react-select/async';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastMsg from '../components/ToastMsg';
import Swal from 'sweetalert2';

const customStylesSelect = {
    control: (provided) => ({
        ...provided,
        height: 'calc(1.5em + 1.3rem + 2px)'
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: 'calc(1.5em + 1.3rem + 2px)'
    })
};


const SendSmsCustomer = (props) => {

    const [customer, setCustomer] = useState(null);
    const [message, setMessage] = useState(null);

    const getCustomer = (inputValue, callback) => {
        if (!inputValue) {
            //callback([]);
            var url = process.env.REACT_APP_API_URL + "customer_search";

        } else {
            var url = process.env.REACT_APP_API_URL + "customer_search?q=" + inputValue;
        }
        const jwt = () => { return JSON.parse(localStorage.getItem('MyToken')); }
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt() },

        };

        setTimeout(() => {
            fetch(url, requestOptions)
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    const tempArray = resp.data.map(function (element) {
                        element.label = element.name + element.mobileNo;
                        element.value = element.id;
                        return element;
                    });
                    callback(tempArray);
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        });
    }

    const handleCustomerChange = (customer) => {
        setCustomer(customer);
        // this.setState({
        //     customer: customer
        // });
    }
    
    const handleMessageChange = (evt) =>{

        setMessage(evt.target.value);

    }

    const handleSubmit = evt => {

        evt.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to create User!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                const formData = {customer: customer,message:message};
                console.log(formData);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(formData)
                };
                fetch(process.env.REACT_APP_API_URL + "send_sms_customer", requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        console.log(resp);

                        if (resp.success == true) {
                            let successMsg = [`User ID# ${resp.data.id}`];

                            toast.success(<ToastMsg toastMessage={successMsg} heading={resp.successMessage} />, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            setMessage(null)
                            setCustomer(null);
                            //this.props.history.push('/user-list');
                        }
                        else {
                            //var errorsMessage = "";
                            var errorsMessage = [];

                            if (resp.errorMessage != undefined && typeof resp.errorMessage === 'object') {
                                var errorsObj = resp.errorMessage;
                                Object.keys(errorsObj).forEach(function (value) {
                                    errorsObj[value].forEach(function (v) {
                                        errorsMessage.push(v)
                                        //errorsMessage += '<div>' + v + '</div>';
                                    });

                                });

                            } else if (resp.errorMessage != undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                                //errorsMessage = resp.errorMessage;
                                errorsMessage.push(resp.errorMessage);
                            } else {
                                //errorsMessage = "Something went wrong";
                                errorsMessage.push("Something went wrong");
                            }
                            //console.log(errorsMessage);
                            toast.error(<ToastMsg toastMessage={errorsMessage} heading={resp.heading} />, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }


                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            }

        })

    }

    return (

        <div className="card card-custom">

            <div className="card-header">
                <h3 className="card-title">Send SMS  Customer</h3>
            </div>
            <div className="col-md-12">
                <div className="featured-box featured-box-primary text-left mt-2">
                    <div className="box-content">

                        <form onSubmit={handleSubmit} >

                            <div className="form-group row">
                                <label for="customer" className="col-lg-2 col-form-label">Customer</label>
                                <div className="col-lg-10">
                                    <AsyncSelect
                                        id="customer"
                                        value={customer}
                                        isMulti
                                        defaultOptions
                                        loadOptions={getCustomer}
                                        //loadOptions={promiseOptions}
                                        placeholder="Select Customer"
                                        onChange={handleCustomerChange}
                                    // onChange={(e) => {
                                    //this.onSearchChange(e);
                                    //  }}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label for="message" className="col-lg-2 col-form-label">Message</label>
                                <div className="col-lg-10">
                                   <textarea className="form-control" value={message} onChange={handleMessageChange} ></textarea> 

                                </div>
                            </div>

                            {/* <pre>
                                {JSON.stringify(this.state, null, 2)}
                            </pre> */}

                            <div className="form-group row">

                                <div className="form-group col-lg-5">
                                    <input type="submit" value="Send" className="btn btn-primary btn-modern float-left" data-loading-text="Loading..." />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default SendSmsCustomer;