/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo,useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";


export function DashboardWidget() {
  
  const [billAmount, setBillAmount] = useState(null);
  const [collectionAmount, setCollectionAmount] = useState(null);

  const [activeCustomerNum, setActiveCustomerNum] = useState(null);
  const [deactiveCustomerNum, setDeactiveCustomerNum] = useState(null);
  const [closedCustomerNum, setClosedCustomerNum] = useState(null);

  React.useEffect(() => {
 
      const jwt = () => {return JSON.parse(localStorage.getItem('MyToken'));}
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+ jwt() },
    
      };
      fetch(process.env.REACT_APP_API_URL + "reports/currentmonth_bill_collection", requestOptions)
        .then((resp) => {
          return resp.json()
        })
        .then((resp) => {
          //this.setState({ fullname: resp.data.fullName,pic:resp.data.fileName });
          const ba = Number(resp.data.billAmount).toFixed(2);
          const ca= Number(resp.data.collectionAmount).toFixed(2);
          setBillAmount(ba);
          setCollectionAmount(ca);
        })
        .catch((error) => {
          //const removeJwt = () => {return  localStorage.removeItem('MyToken');} 
          // removeJwt();
          //window.location.reload(); 
          console.log(error, "catch the hoop")
        });

        fetch(process.env.REACT_APP_API_URL + "reports/tatal_customer", requestOptions)
        .then((resp) => {
          return resp.json()
        })
        .then((resp) => {
          setActiveCustomerNum(resp.data.activeCustomer);
          setDeactiveCustomerNum(resp.data.deactiveCustomer);
          setClosedCustomerNum(resp.data.closedCustomer);
          
        })
        .catch((error) => {
          //const removeJwt = () => {return  localStorage.removeItem('MyToken');} 
          // removeJwt();
          //window.location.reload(); 
          console.log(error, "catch the hoop")
        });
    
    
    
  }, []); // <-- Have to pass in [] here!


  return (
    <div className={`card card-custom bg-gray-100 `}>
    <div className="card-body p-0 position-relative overflow-hidden">
      <div style={{ padding: '10px' }}>
        <div className="row m-0">

          <div className="col bg-light-primary px-6 py-8 rounded-xl mr-7 mb-7">

            <div className="d-flex flex-column text-left">
              <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}
                ></SVG>
              </span>
              <span className="text-dark-75 font-weight-bolder font-size-h3">{billAmount + ' BDT'}</span>
              <span className="text-primary font-weight-bold font-size-h6 mt-2">Current Month Total Bill</span>
            </div>

          </div>

          <div className="col bg-light-primary px-6 py-8 rounded-xl mb-7">


            <div className="d-flex flex-column text-left">
            <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}
                ></SVG>
              </span>
              <span className="text-dark-75 font-weight-bolder font-size-h3">{collectionAmount + ' BDT'}</span>
              <span className="text-primary font-weight-bold font-size-h6 mt-2">Current Month Total Collection</span>
            </div>


          </div>

        </div>
        <div className="row m-0">

        <div className="col bg-light-primary px-6 py-8 rounded-xl mr-7">
            <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              ></SVG>
            </span>
          
            <a
              href="#"
              className="text-primary font-weight-bold font-size-h6 mt-2"
            >
              {activeCustomerNum} Active Customer  
            </a>
          </div>


          <div className="col bg-light-warning px-6 py-8 rounded-xl mr-7">
            <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Delete-user.svg")}
              ></SVG>
            </span>
            <a
              href="#"
              className="text-danger font-weight-bold font-size-h6 mt-2"
            >
             {deactiveCustomerNum} Deactive Customer
            </a>
          </div>

          <div className="col bg-light-danger px-6 py-8 rounded-xl">
            <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Delete-user.svg"
                )}
              ></SVG>
            </span>
            <a
              href="#"
              className="text-danger font-weight-bold font-size-h6 mt-2"
            >
             {closedCustomerNum} Closed Customer
            </a>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  );
}
export default DashboardWidget;