import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import { Tab, Tabs, Nav, Col, Row, Button, InputGroup, Modal } from "react-bootstrap";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import ToastMsg from '../components/ToastMsg';
import Swal from 'sweetalert2';

class CustomerNetwork extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customer: "",
            isPppoeOrStatic: "pppoe",
            pppoe: "",
            pppoeUserName: "",
            pppoePassword: "",
            localAddress: "",
            remoteAddress: "",
            ipAddress: "",
            subnetMask: "",
            defaultGateway: "",
            primaryDns: "",
            alternateDns: "",
            onu: "",
            onuMacAddress: "",
            isCreateBill:"no",
            note:"",
            areaOption: [],
            zoneOption: [],
            packageOption: []
        };
    }

    componentDidMount() {
        this.baseState = this.state;

    }

    handleInputOnChange = evt => {
        this.setState({ [evt.target.name]: evt.target.value });
    };

    getCustomer = (inputValue, callback) => {
        if (!inputValue) {
            //callback([]);
            var url = process.env.REACT_APP_API_URL + "customer_search";

        } else {
            var url = process.env.REACT_APP_API_URL + "customer_search?q=" + inputValue;
        }

        const jwt = () => { return JSON.parse(localStorage.getItem('MyToken')); }
        const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt() },
    
        };

        setTimeout(() => {
            fetch(url, requestOptions)
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    const tempArray = resp.data.map(function (element) {
                        element.label = element.name + element.mobileNo;
                        element.value = element.id;
                        return element;
                    });
                    callback(tempArray);
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        });
    }

    handleCustomerChange = (customer) => {

        this.setState({
            customer: customer
        }, () => {
            this.getCustomerDetails(customer.id);
        });
    }

    getCustomerDetails(customerId) {
        fetch(process.env.REACT_APP_API_URL + "customer_details/" + customerId, {
            method: "GET",
        })
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                this.setState({
                    connectionDate: resp.connection_date,
                    monthlyFee: resp.monthlyFee,
                    isPppoeOrStatic: resp.pppoe=="pppoe"? "pppoe": "static",
                    pppoe: resp.pppoe,
                    pppoeUserName: resp.pppoeUserName,
                    pppoePassword: resp.pppoePassword,
                    localAddress: resp.localAddress,
                    remoteAddress: resp.remoteAddress,
                    ipAddress: resp.ipAddress,
                    subnetMask: resp.subnetMask,
                    defaultGateway: resp.defaultGateway,
                    primaryDns: resp.primaryDns,
                    alternateDns: resp.alternateDns,
                    onu: resp.onu,
                    onuMacAddress: resp.onuMacAddress

                });
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }


    handleSubmit = evt => {

        evt.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to add Network Info!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                const { areaOption, blockOption, ...formData } = this.state;
                console.log(formData);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(formData)
                };
                fetch(process.env.REACT_APP_API_URL + "customer_network", requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        console.log(resp);

                        if (resp.success == true) {
                            let successMsg = [`Customer ID# ${resp.data.id}`];

                            toast.success(<ToastMsg toastMessage={successMsg} heading={resp.successMessage} />, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            this.setState(this.baseState);
                            this.props.history.push('/customer-list');
                        }
                        else {
                            //var errorsMessage = "";
                            var errorsMessage = [];

                            if (resp.errorMessage != undefined && typeof resp.errorMessage === 'object') {
                                var errorsObj = resp.errorMessage;
                                Object.keys(errorsObj).forEach(function (value) {
                                    errorsObj[value].forEach(function (v) {
                                        errorsMessage.push(v)
                                        //errorsMessage += '<div>' + v + '</div>';
                                    });

                                });

                            } else if (resp.errorMessage != undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                                //errorsMessage = resp.errorMessage;
                                errorsMessage.push(resp.errorMessage);
                            } else {
                                //errorsMessage = "Something went wrong";
                                errorsMessage.push("Something went wrong");
                            }
                            //console.log(errorsMessage);
                            toast.error(<ToastMsg toastMessage={errorsMessage} heading={resp.heading} />, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }


                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });

            }
        })

    }

    render() {
        const categoryOption = [{ name: 'Regular', value: 'regular' }, { name: 'Complimentary', value: 'complimentary' }];

        return (
            <div className="card card-custom">
                <div className="card-header">
                    <h3 className="card-title">
                        Customer Network Info
                </h3>
                </div>


                <div className="card-body">

                    <form onSubmit={this.handleSubmit} >

                        <div className="form-group row">
                            <label for="customer" className="col-lg-2 col-form-label">Customer</label>
                            <div className="col-lg-10">
                                <AsyncSelect
                                    id="customer"
                                    value={this.state.customer}
                                    defaultOptions
                                    loadOptions={this.getCustomer}
                                    //loadOptions={promiseOptions}
                                    placeholder="Select Customer"
                                    onChange={this.handleCustomerChange}
                                // onChange={(e) => {
                                //this.onSearchChange(e);
                                //  }}
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-lg-2 col-form-label">PPPoE or Static</label>
                            <div className="col-lg-10 col-form-label">
                                <div className="radio-list">
                                    <label className="radio">
                                        <input type="radio" value="pppoe"
                                            checked={this.state.isPppoeOrStatic === "pppoe"} name="isPppoeOrStatic" onChange={this.handleInputOnChange} />
                                        <span></span>
                                    PPPoE
                                    </label>
                                    <label className="radio">
                                        <input type="radio" value="static"
                                            checked={this.state.isPppoeOrStatic === "static"} name="isPppoeOrStatic" onChange={this.handleInputOnChange} />
                                        <span></span>
                                    Static
                                </label>

                                </div>
                            </div>
                        </div>
                        {this.state.isPppoeOrStatic === "pppoe" &&
                            <>
                                <div className="form-group row">
                                    <label for="pppoe" className="col-lg-2 col-form-label">PPPoE</label>
                                    <div className="col-lg-10">
                                        <input className="form-control" type="text" name="pppoe" id="pppoe" value={this.state.pppoe} onChange={this.handleInputOnChange} />
                                    </div>
                                </div>

                                <div className="form-group row">

                                    <div className="col-lg-6">
                                        <TextField name="pppoeUserName" value={this.state.pppoeUserName} onChange={this.handleInputOnChange} label="User Name" variant="outlined" size="small" fullWidth />
                                    </div>
                                    <div className="col-lg-6">
                                        <TextField name="pppoePassword" value={this.state.pppoePassword} onChange={this.handleInputOnChange} label="Password" variant="outlined" size="small" fullWidth />
                                    </div>

                                </div>

                                <div className="form-group row">
                                    <label for="localAddress" className="col-lg-2 col-form-label">Local Address</label>
                                    <div className="col-lg-10">
                                        <input className="form-control" type="text" name="localAddress" id="localAddress" value={this.state.localAddress} onChange={this.handleInputOnChange} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label for="remoteAddress" className="col-lg-2 col-form-label">Remote Address</label>
                                    <div className="col-lg-10">
                                        <input className="form-control" type="text" name="remoteAddress" id="remoteAddress" value={this.state.remoteAddress} onChange={this.handleInputOnChange} />
                                    </div>
                                </div>
                            </>
                        }

                        {this.state.isPppoeOrStatic === "static" &&
                            <>
                                <div className="form-group row">
                                    <label for="ipAddress" className="col-lg-2 col-form-label">IP Address</label>
                                    <div className="col-lg-10">
                                        <input className="form-control" type="text" name="ipAddress" id="ipAddress" value={this.state.ipAddress} onChange={this.handleInputOnChange} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label for="subnetMask" className="col-lg-2 col-form-label">Subnet Mask</label>
                                    <div className="col-lg-10">
                                        <input className="form-control" type="text" name="subnetMask" id="subnetMask" value={this.state.subnetMask} onChange={this.handleInputOnChange} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label for="defaultGateway" className="col-lg-2 col-form-label">Default Gateway</label>
                                    <div className="col-lg-10">
                                        <input className="form-control" type="text" name="defaultGateway" id="defaultGateway" value={this.state.defaultGateway} onChange={this.handleInputOnChange} />
                                    </div>
                                </div>



                            </>
                        }

                        <div className="form-group row">
                            <label for="onu" className="col-lg-2 col-form-label">Technical Details</label>
                            <div className="col-lg-10">
                                <input className="form-control" type="text" name="onu" id="onu" value={this.state.onu} onChange={this.handleInputOnChange} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="onuMacAddress" className="col-lg-2 col-form-label">ONU Mac Address</label>
                            <div className="col-lg-10">
                                <input className="form-control" type="text" name="onuMacAddress" id="onuMacAddress" value={this.state.onuMacAddress} onChange={this.handleInputOnChange} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label for="note" className="col-lg-2 col-form-label">Note</label>
                            <div className="col-lg-10">
                                <textarea className="form-control"  name="note" id="note" value={this.state.note} onChange={this.handleInputOnChange} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-lg-2 col-form-label">Create Bill on Connection Date</label>
                            <div className="col-lg-10 col-form-label">
                                <div className="radio-list">
                                    <label className="radio">
                                        <input type="radio" value="yes"
                                            checked={this.state.isCreateBill === "yes"} name="isCreateBill" onChange={this.handleInputOnChange} />
                                        <span></span>
                                    Yes
                                    </label>
                                    <label className="radio">
                                        <input type="radio" value="no"
                                            checked={this.state.isCreateBill === "no"} name="isCreateBill" onChange={this.handleInputOnChange} />
                                        <span></span>
                                    No
                                </label>

                                </div>
                            </div>
                        </div>



                        <div className="form-group row">

                            <div className="form-group col-lg-5">
                                <input type="submit" value="Save" className="btn btn-primary btn-modern float-left" data-loading-text="Loading..." />
                            </div>
                        </div>

                    </form>

                </div>
                {/* <pre>
                    {JSON.stringify(this.state, null, 2)}
                </pre> */}

                <ToastContainer />
            </div>



        );
    }
}

export default CustomerNetwork;