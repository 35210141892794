import React, { Component } from 'react';
import PackageDt from '../components/Packagedt';
import PackageCreate from '../components/PackageCreate';

class Package extends Component {

    constructor(props) {
        super(props);
        this.state = { newPackage: "", id: "", name: "" }
    }
    onCreate = (newPackage) => {
        console.log(newPackage);
        this.setState({ newPackage: newPackage });
    }
    onAction = (id, name) => {
        this.setState({ id: id, name: name });
    }
    render() {
        return (
            <div className="card card-custom">

                <div className="card-header">
                    <h3 className="card-title"> Package Manage</h3>
                </div>
                
                <div className="card-body">
                    <PackageCreate onCreate={this.onCreate} id={this.state.id} name={this.state.name} />
                    <PackageDt addnewPackage={this.state.newPackage} onAction={this.onAction} />
                </div>

            </div >

        );
    }
}

export default Package;