import React, { Component } from 'react';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastMsg from './ToastMsg';

class ZoneCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            name: "",
            areaOption: [],

        };
    }

    componentDidMount() {
        this.baseState = this.state;
    
        
    }
    componentDidUpdate(prevProps) {
        if(this.props.id != prevProps.id || this.props.name != prevProps.name) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
        {
   
            this.getZoneDetails(this.props.id);
        
        }
        
    }

    getZoneDetails(id) {
        fetch(process.env.REACT_APP_API_URL + "zone_details/"+id, {
            method: "GET",
        })
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                this.setState({ id: resp.id,name:resp.name});
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }

    handleInputOnChange = evt => {
        this.setState({ [evt.target.name]: evt.target.value });
    };

    handleSubmit = evt => {

        evt.preventDefault();
        const { areaOption, ...formData } = this.state;
        console.log(formData);
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formData)
        };
        if(this.state.id != ""){
            var apiEnd = "zone_update";
        }
        else{
            var apiEnd = "zone_create";
        }
        fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
          .then((resp) => {
            return resp.json()
          })
          .then((resp) => {
            console.log(resp);
    
            if (resp.success == true) {
              let successMsg = [`Zone ID# ${resp.data.id}`];
    
              toast.success(<ToastMsg toastMessage={successMsg} heading={resp.successMessage} />, {
                position: toast.POSITION.TOP_RIGHT
              });
              this.setState(this.baseState);
              this.props.onCreate({id:resp.data.id,name:resp.data.name});
            }
            else {
              //var errorsMessage = "";
              var errorsMessage = [];
    
              if (resp.errorMessage != undefined && typeof resp.errorMessage === 'object') {
                var errorsObj = resp.errorMessage;
                Object.keys(errorsObj).forEach(function (value) {
                  errorsObj[value].forEach(function (v) {
                    errorsMessage.push(v)
                    //errorsMessage += '<div>' + v + '</div>';
                  });
    
                });
    
              } else if (resp.errorMessage != undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                //errorsMessage = resp.errorMessage;
                errorsMessage.push(resp.errorMessage);
              } else {
                //errorsMessage = "Something went wrong";
                errorsMessage.push("Something went wrong");
              }
              //console.log(errorsMessage);
              toast.error(<ToastMsg toastMessage={errorsMessage} heading={resp.heading} />, {
                position: toast.POSITION.TOP_RIGHT
              });
            }
            
    
          })
          .catch((error) => {
            console.log(error, "catch the hoop")
          });
    
      }

    render() {

        return (
            <>
            <form onSubmit={this.handleSubmit}>

                <div className="form-row">
                    <div className="form-group col">
                        <input type="text" name="name" value={this.state.name} onChange={this.handleInputOnChange} placeholder="Name" className="form-control form-control-md" />
                    </div>
                </div>


                <div className="form-group row">

                    <div className="form-group col-lg-5">
                        <input type="submit" value="Save" className="btn btn-primary btn-modern float-left" data-loading-text="Loading..." />
                    </div>
                </div>
            </form> 
            <ToastContainer />
             </>               
        );
    }
}

export default ZoneCreate;