import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import ToastMsg from '../components/ToastMsg';
import TextField from '@material-ui/core/TextField';
import Swal from 'sweetalert2'

class Billing extends Component {

    constructor(props) {
        super(props);
        this.state = { newBilling: "", id: "", billDate: "",  isLoading: false  }
    }
    onCreate = (newBilling) => {
        console.log(newBilling);
        this.setState({ newBilling: newBilling });
    }
    onAction = (id, name) => {
        this.setState({ id: id, name: name });
    }

    handleDateChange = (value) => {
        console.log(value);
        this.setState({ billDate: value });
    }
    
    handleInputOnChange = evt => {
        this.setState({ [evt.target.name]: evt.target.value });
    };

    handleSubmit = evt => {
        evt.preventDefault();
       

        Swal.fire({
            title: 'Are you sure?',
            text: "You want to generate bill !",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                this.setState({ isLoading: true });
                const jwt = () => { return JSON.parse(localStorage.getItem('MyToken')); }
                const { ...formData } = this.state;
                //console.log(formData);

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt() },
                    body: JSON.stringify(formData)
                };
                fetch(process.env.REACT_APP_API_URL + "bill_generate", requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        console.log(resp);
        
                        if (resp.success == true) {
                            let successMsg = [`Bill date# ${resp.data.name}`];
        
                            toast.success(<ToastMsg toastMessage={successMsg} heading={resp.successMessage} />, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            this.setState(this.baseState);
                            this.setState({ isLoading: false });
                            //this.props.history.push('/customer-list');
                        }
                        else {
                            //var errorsMessage = "";
                            var errorsMessage = [];
        
                            if (resp.errorMessage != undefined && typeof resp.errorMessage === 'object') {
                                var errorsObj = resp.errorMessage;
                                Object.keys(errorsObj).forEach(function (value) {
                                    errorsObj[value].forEach(function (v) {
                                        errorsMessage.push(v)
                                        //errorsMessage += '<div>' + v + '</div>';
                                    });
        
                                });
        
                            } else if (resp.errorMessage != undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                                //errorsMessage = resp.errorMessage;
                                errorsMessage.push(resp.errorMessage);
                            } else {
                                //errorsMessage = "Something went wrong";
                                errorsMessage.push("Something went wrong");
                            }
                            //console.log(errorsMessage);
                            toast.error(<ToastMsg toastMessage={errorsMessage} heading={resp.heading} />, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }
                        this.setState({ isLoading: false });
        
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                        this.setState({ isLoading: false });
                    });

            }
        })
       

    }

    render() {
        return (
            <div className="card card-custom">
                <div className="card-header">
                    <h3 className="card-title">Billing</h3>
                </div>
                <div className="card-body">


                    <form onSubmit={this.handleSubmit} >
                        
                    <div className="form-group row">

                        <TextField type="date" name="billDate" value={this.state.billDate} onChange={this.handleInputOnChange} InputLabelProps={{
                            shrink: true,
                        }} label="Bill Date" variant="outlined" size="small" fullWidth />

                    </div>
                        <div className="form-group row">

                            <div className="form-group col-lg-5">
                            <button 
                                    type="submit" 
                                    className="btn btn-primary btn-modern float-left" 
                                    disabled={this.state.isLoading}
                                >
                                    {this.state.isLoading ? (
                                        <>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Generating...
                                        </>
                                    ) : (
                                        "Generate"
                                    )}
                                </button>
                            </div>
                        </div>
                    </form>

                </div>

                <ToastContainer />
            </div>

        );
    }
}

export default Billing;